import 'stylesheets/v4.scss'
import Rails from '@rails/ujs';

require('jquery');
require.context('../assets', true)

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
Rails.start();

import './mobile_header';
import './papers';
import './searchmodal';
import './my_desk';