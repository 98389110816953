import $ from 'jquery';

$(document).ready(() => {
  const searchModal = document.querySelector('.main-search');
  const classSearchModal = document.querySelector('.class-search');
  const subjectSearchModal = document.querySelector('.subject-search');
  const closeSearchModalButton = document.querySelectorAll(
    '.main-search-close'
  );

  function closeSearchModal() {
    searchModal.classList.remove('fadeIn');
    searchModal.classList.add('fadeOut');
    setTimeout(() => {
      searchModal.style.display = 'none';
    }, 50);
  }

  function closeClassSearchModal() {
    classSearchModal.classList.remove('fadeIn');
    classSearchModal.classList.add('fadeOut');
    setTimeout(() => {
      classSearchModal.style.display = 'none';
    }, 50);
  }

  function closeSubjectSearchModal() {
    subjectSearchModal.classList.remove('fadeIn');
    subjectSearchModal.classList.add('fadeOut');
    setTimeout(() => {
      subjectSearchModal.style.display = 'none';
    }, 50);
  }

  function openSearchModal() {
    searchModal.classList.remove('fadeOut');
    searchModal.classList.add('fadeIn');
    searchModal.style.display = 'flex';
  }

  function openClassSearchModal() {
    classSearchModal.classList.remove('fadeOut');
    classSearchModal.classList.add('fadeIn');
    classSearchModal.style.display = 'flex';
  }

  function openSubjectsSearchModal() {
    subjectSearchModal.classList.remove('fadeOut');
    subjectSearchModal.classList.add('fadeIn');
    subjectSearchModal.style.display = 'flex';
  }

  for (let i = 0; i < closeSearchModalButton.length; i++) {
    const elements = closeSearchModalButton[i];
    elements.onclick = (e) => {
      e.preventDefault();
      closeSearchModal();
      if (classSearchModal) {
        closeClassSearchModal();
      }
    };
    searchModal.style.display = 'none';
    if (classSearchModal) {
      classSearchModal.style.display = 'none';
    }
    window.onclick = function (event) {
      if (event.target == searchModal) closeSearchModal();
      if (classSearchModal) {
        if (event.target == classSearchModal) closeClassSearchModal();
      }
    };
  }

  $('.search-button, .class-search-button').on('click', (e) => {
    if ($(e.target).hasClass('class-search-button')) {
      openClassSearchModal();
      $('#classes-search-box').find('input').focus();
    } else {
      openSearchModal();
      $('#globalsearchbox').find('input').focus();
    }
  });

  $('.subjects-search-button').on('click', (e) => {
    openSubjectsSearchModal();
    $('#subjects-search-box').find('input').focus();
  });

  $('.subject-search-close').on('click', (e) => {
    closeSubjectSearchModal();
  });

  // if ($(this).hasClass('subject-search')) {
  //   $('.main-search').data('subjectId', 'subject');
  // } else if ($(this).hasClass('global-search')) {
  //   $('.main-search').data('searchType', 'global');
  // }

  function instantSearchClient() {
    const searchClient = algoliasearch(
      window.algoliaCredentials.applicationId,
      window.algoliaCredentials.searchApiKey
    );
    return instantsearch({
      indexName: window.algoliaIndices.resource,
      searchClient,
      searchFunction: function (helper) {
        if (helper.state.query.length === 0) {
          return; // do not trigger search
        }
        helper.search(); // trigger search
      },
    });
  }

  function initializeGlobalSearch() {
    const search = instantSearchClient();

    search.addWidgets([
      instantsearch.widgets.searchBox({
        autofocus: true,
        placeholder: 'Search iRevise...',
        container: '#globalsearchbox',
        searchAsYouType: true,
        showSubmit: false,
        showReset: true,
        showLoadingIndicator: false,
        cssClasses: {
          input:
            'form-input p-6 pr-16 block w-full border border-gray-300 border-solid',
          reset: 'absolute top-0 right-0 p-6',
          resetIcon: 'hidden',
        },
        templates: {
          reset: `
            <svg class="fill-current text-brand-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          `,
        },
      }),
      instantsearch.widgets.hits({
        container: '#resource-search-results',
        cssClasses: {
          item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
        },
        templates: {
          empty: `
            <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
              <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
            </div>
          `,
          item: `
            <a href={{ v4_resource_link }} >
              <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
              <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                {{ taxon_name }}
                <span class="text-gray-500"> {{ level_names }} </span>
              </p>
            </a>
          `,
        },
      }),
      instantsearch.widgets.stats({
        container: '#resource-results-count',
        template: {
          text: `
            {{#hasNoResults}} - No results{{/hasNoResults}}
            {{#hasOneResult}} - 1 result{{/hasOneResult}}
            {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
          `,
        },
      }),
      instantsearch.widgets
        .index({
          indexName: window.algoliaIndices.examPaper,
        })
        .addWidgets([
          instantsearch.widgets.configure({
            filters: `user_id = ${window.currentUser}`,
          }),
          instantsearch.widgets.hits({
            container: '#exam-papers-search-results',
            cssClasses: {
              item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
            },
            templates: {
              empty: `
                <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
                  <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
                </div>
              `,
              item: `
                <a href={{ v4_paper_link }} >
                  <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
                  <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                    {{ taxon_name }}
                    <span class="text-gray-500"> {{ level_names }} </span>
                  </p>
                </a>
              `,
            },
          }),
          instantsearch.widgets.stats({
            container: '#exam-papers-results-count',
            template: {
              text: `
                {{#hasNoResults}} - No results{{/hasNoResults}}
                {{#hasOneResult}} - 1 result{{/hasOneResult}}
                {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
              `,
            },
          }),
        ]),
      instantsearch.widgets
        .index({
          indexName: window.algoliaIndices.tutorialSeries,
        })
        .addWidgets([
          instantsearch.widgets.hits({
            container: '#classes-search-results',
            cssClasses: {
              item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
            },
            templates: {
              empty: `
                <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
                  <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
                </div>
              `,
              item: `
                <a href={{ v4_class_link }}>
                  <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
                  <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                    {{ taxon_name }}
                    <span class="text-gray-500"> {{ level_names }} </span>
                  </p>
                </a>
              `,
            },
          }),
          instantsearch.widgets.stats({
            container: '#classes-results-count',
            template: {
              text: `
                {{#hasNoResults}} - No results{{/hasNoResults}}
                {{#hasOneResult}} - 1 result{{/hasOneResult}}
                {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
              `,
            },
          }),
        ]),
    ]);

    let filters = `site_code: ${window.currentSite}`;
    search.addWidgets([
      instantsearch.widgets.configure({
        filters: filters,
      }),
    ]);

    search.start();
  }

  function initializeSubjectsSearch() {
    const search = instantSearchClient();

    // let subjectId = $('.subject-search').data('subjectId');
    search.addWidgets([
      // instantsearch.widgets.configure({
      //   filters: `exam_subject_id=${subjectId}`,
      // }),
      instantsearch.widgets.searchBox({
        autofocus: true,
        placeholder: 'Search in subjects',
        container: '#subjects-search-box',
        searchAsYouType: true,
        showSubmit: false,
        showReset: true,
        showLoadingIndicator: false,
        cssClasses: {
          input:
            'form-input p-6 pr-16 block w-full border border-gray-300 border-solid',
          reset: 'absolute top-0 right-0 p-6',
          resetIcon: 'hidden',
        },
        templates: {
          reset: `
            <svg class="fill-current text-brand-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          `,
        },
      }),
      instantsearch.widgets.hits({
        container: '#subjects-resource-search-results',
        cssClasses: {
          item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
        },
        templates: {
          empty: `
            <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
              <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
            </div>
          `,
          item: `
            <a href={{ v4_resource_link }} >
              <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
              <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                {{ taxon_name }}
                <span class="text-gray-500"> {{ level_names }} </span>
              </p>
            </a>
          `,
        },
      }),
      instantsearch.widgets.stats({
        container: '#subjects-resource-results-count',
        template: {
          text: `
            {{#hasNoResults}} - No results{{/hasNoResults}}
            {{#hasOneResult}} - 1 result{{/hasOneResult}}
            {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
          `,
        },
      }),
      instantsearch.widgets
        .index({
          indexName: window.algoliaIndices.examPaper,
        })
        .addWidgets([
          instantsearch.widgets.configure({
            filters: `user_id = ${window.currentUser}`,
          }),
          instantsearch.widgets.hits({
            container: '#subjects-exam-papers-search-results',
            cssClasses: {
              item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
            },
            templates: {
              empty: `
                <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
                  <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
                </div>
              `,
              item: `
                <a href={{ v4_paper_link }} >
                  <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
                  <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                    {{ taxon_name }}
                    <span class="text-gray-500"> {{ level_names }} </span>
                  </p>
                </a>
              `,
            },
          }),
          instantsearch.widgets.stats({
            container: '#subjects-exam-papers-results-count',
            template: {
              text: `
                {{#hasNoResults}} - No results{{/hasNoResults}}
                {{#hasOneResult}} - 1 result{{/hasOneResult}}
                {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
              `,
            },
          }),
        ]),
      instantsearch.widgets
        .index({
          indexName: window.algoliaIndices.tutorialSeries,
        })
        .addWidgets([
          instantsearch.widgets.hits({
            container: '#subjects-classes-search-results',
            cssClasses: {
              item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
            },
            templates: {
              empty: `
                <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
                  <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
                </div>
              `,
              item: `
                <a href={{ v4_class_link }}>
                  <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
                  <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                    {{ taxon_name }}
                    <span class="text-gray-500"> {{ level_names }} </span>
                  </p>
                </a>
              `,
            },
          }),
          instantsearch.widgets.stats({
            container: '#subjects-classes-results-count',
            template: {
              text: `
                {{#hasNoResults}} - No results{{/hasNoResults}}
                {{#hasOneResult}} - 1 result{{/hasOneResult}}
                {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
              `,
            },
          }),
        ]),
    ]);

    let filters = `site_code:${window.currentSite}`;
    let subjectId = $('.subject-search').data('subjectId');
    if (subjectId) {
      filters += ` AND subject_ids: ${subjectId}`;
    }

    search.addWidgets([
      instantsearch.widgets.configure({
        filters: filters,
      }),
    ]);

    search.start();
  }

  function initializeClassesSearch() {
    const search = instantSearchClient();
    search.addWidgets([
      instantsearch.widgets.searchBox({
        autofocus: true,
        placeholder: 'Search Classes...',
        container: '#classes-search-box',
        searchAsYouType: true,
        showSubmit: false,
        showReset: true,
        showLoadingIndicator: false,
        cssClasses: {
          input:
            'form-input p-6 pr-16 block w-full border border-gray-300 border-solid',
          reset: 'absolute top-0 right-0 p-6',
          resetIcon: 'hidden',
        },
        templates: {
          reset: `
            <svg class="fill-current text-brand-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          `,
        },
      }),
      instantsearch.widgets.hits({
        container: '#explore-classes-search-results',
        cssClasses: {
          item: 'rounded-md bg-white shadow-md px-4 py-3 mb-2',
        },
        templates: {
          empty: `
            <div class="rounded-md bg-white shadow-md px-4 py-3 mb-2">
              <p class="text-base font-semibold leading-5 text-black mb-1"> No Results </p>
            </div>
          `,
          item: `
            <a href={{ v4_class_link }} >
              <p class="text-base font-semibold leading-5 text-black mb-1"> {{  name }} </p>
              <p class="text-xs font-semibold leading-5 text-black mb-1 uppercase">
                {{ taxon_name }}
                <span class="text-gray-500"> {{ level_names }} </span>
              </p>
            </a>
          `,
        },
      }),
      instantsearch.widgets.stats({
        container: '#explore-classes-results-count',
        template: {
          text: `
            {{#hasNoResults}} - No results{{/hasNoResults}}
            {{#hasOneResult}} - 1 result{{/hasOneResult}}
            {{#hasManyResults}} - {{nbHits}} results {{/hasManyResults}}
          `,
        },
      }),
    ]);
    search.start();
  }

  initializeGlobalSearch();

  const classSearchBox = document.querySelector('#classes-search-box');
  if (classSearchBox) {
    initializeClassesSearch();
  }

  const subjectsSearchBox = document.querySelector('#subjects-search-box');
  if (subjectsSearchBox) {
    initializeSubjectsSearch();
  }
});
