import $ from 'jquery';

$(document).ready(() => {
  $('.past-paper').on('click', () => {
    $('.past-papers')[0].style.display = 'block';
  })

  $('.past-paper').on('click', () => {
    $('.custom-papers')[0].style.display = 'none';
  });
});



$(document).ready(() => {
    $('.custom-paper').on('click', () => {
      $('.custom-papers')[0].style.display = 'block';
    })
  
    $('.custom-paper').on('click', () => {
      $('.past-papers')[0].style.display = 'none';
    });
  });

