import React from 'react';
import FileViewer from './components/react-file-viewer/components/file-viewer.jsx';

export default class ResourceViewer extends React.Component {
  render() {
    return (
      <>
        <FileViewer
          fileType={this.props.fileType}
          filePath={this.props.fileUrl}
          onError={this.onError}
        />
      </>
    );
  }

  onError(e) {
    console.log(e);
  }
}
