import $ from 'jquery';

$(document).ready(() => {
  $('.mobile-header .burger-menu').on('click', () => {
    $('.sidebar')[0].style.display = 'flex';
  })

  $('.sidebar .closebtn').on('click', () => {
    $('.sidebar')[0].style.display = 'none';
  });
});




